import { CarrierEditLoadOption } from 'harmony-constants';
import { CarrierConfigurableLoadSetting, Driver, OrganizationCarrier, Tractor, Trailer } from '../../../../types';

const OwningOrgSettings = [CarrierEditLoadOption.Owning, CarrierEditLoadOption.Both];
const CarrierSettings = [CarrierEditLoadOption.Carrier, CarrierEditLoadOption.Both];

export const loadCellIsEditable = (propertyName: CarrierConfigurableLoadSetting, currentCarrier: OrganizationCarrier, currentUserIsCarrier: boolean): boolean => {
    const currentSetting = currentCarrier[propertyName];
    return currentUserIsCarrier ? CarrierSettings.includes(currentSetting) : OwningOrgSettings.includes(currentSetting);
};

type ResourceOption = Tractor | Trailer | Driver

export const carrierResourceSettings = <T extends ResourceOption>(list: T[], currentUserIsCarrier: boolean, userOrgId: number, currentCarrier: OrganizationCarrier, propertyName: CarrierConfigurableLoadSetting) => {
    const currentSetting = currentCarrier[propertyName];
    const disabled = currentUserIsCarrier ? currentSetting === CarrierEditLoadOption.Owning : currentSetting === CarrierEditLoadOption.Carrier;

    let filteredList: T[] = [];
    if (disabled) {
        filteredList = list;
    } else {
        list.map(item => {
            // user can select resource from own org reguardless of any settings
            if (item.organizationId === userOrgId) {
                filteredList.push(item);
            }
            // if both - owning org can see just ASSIGNED carriers resources.  Carrier can see owning org resources
            if (currentSetting === CarrierEditLoadOption.Both) {
                if (currentUserIsCarrier) {
                    // show owning org resource to carrier
                    if (item.organizationId === currentCarrier.organizationId) {
                        filteredList.push(item);
                    }
                } else {
                    // show carrier resource to owning org
                    if (item.organizationId === currentCarrier.carrierOrganizationId) {
                        filteredList.push(item);
                    }
                }
            }
        });
    }

    return {
        resources: filteredList,
        isDisabled: disabled,
    }
}
