import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import List from '@mui/material/List';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';
import { OrgQueryKeys } from '../../api/config';
import Skeleton from "@mui/material/Skeleton";
import { DeviceLocation, DeviceLocationParsed, Driver } from '../../types';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { useLoadsInTransit } from '../../api/queries/use-loads-in-transit';
import WarningIcon from '@mui/icons-material/Warning';
import Popover from '@mui/material/Popover';
import {
    buildDupeResourceList,
    findDuplicateResourceIdsForFields,
    findFieldsWithDuplicatesForLocation,
    markDuplicateFieldsForLocations
} from './utils/live-map-list-util';
import Divider from '@mui/material/Divider';

const DeviceCard = styled(Card)({
    border: '1px solid transparent',
    '&> div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '.8rem',
        padding: '.4rem .4rem .6rem .8rem',
        color: '#666',
        justifyContent: 'space-between',

        '&:last-child': {
            paddingBottom: '.4rem'
        },
        '& span': {
            color: '#000'
        },
        '&.selected': {
            borderColor: 'red',
        }
    },
});

const StyledPopover = styled(Popover)({
    pointerEvents: 'none',
    maxWidth: '30rem',
    '& .MuiPopover-paper': {
        padding: '0.5rem',
    }
})

const Timestamp = styled('div')({
    paddingTop: '.3rem',
    fontSize: '.7rem'
})

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
    fontSize: '1.1rem',
    color: theme.palette.error.main,
}));

const DeviceListWarningContainer = styled('div')({
    position: 'absolute',
    right: '0.5rem',
});

const DeviceListItem: React.FC<{ deviceLocation: DeviceLocationParsed, onClick: (deviceLocation: DeviceLocationParsed) => void, isSelected: boolean }> = (props) => {
    const { deviceLocation: dl, onClick, isSelected } = props;

    const executeScroll = () => scrollRef?.current?.scrollIntoView && scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
    })

    const { tractors, trailers, isLoading: isLoadingEquipment } = useTractorTrailer();
    const { data: drivers, isLoading: isLoadingDrivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: loads, isLoading: isLoadingLoads } = useLoadsInTransit();

    const scrollRef = useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (isSelected) {
            executeScroll();
        }
    }, [isSelected]);

    const isLoading = isLoadingEquipment || isLoadingDrivers || isLoadingLoads;
    const tractor = tractors?.find(t => t.id === dl.tractorId)?.userDisplayName;
    const trailer = trailers?.find(t => t.id === dl.trailerId)?.userDisplayName;
    const load = loads?.find(l => l.id === dl.orderId);
    const driver = drivers?.find(d => d.id === dl.userId);
    const driverName = driver ? `${driver.lastName}, ${driver.firstName}` : '';
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderDupeResourceWarning = (deviceLocation: DeviceLocationParsed) => {
        const duplicateIdFields = findFieldsWithDuplicatesForLocation(deviceLocation);
        if (duplicateIdFields.length < 1) return null;
        return (
            <>
                <DeviceListWarningContainer onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                    <StyledWarningIcon />
                </DeviceListWarningContainer>
                <StyledPopover
                    id='mouse-over-popover'
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography align='center' variant='subtitle1'>{L.duplicateResourcesFound()}</Typography>
                    <Typography align='center' variant='body2'
                                color='error'>{buildDupeResourceList(duplicateIdFields)}</Typography>
                    <Divider sx={{ margin: '0.5rem'}} />
                    <Typography align='center' variant='subtitle2'>{L.duplicateResourcesFoundMessage()}</Typography>
                </StyledPopover>
            </>
        );
    };

    const renderContent = () => {
        return (
            <div>
                {renderDupeResourceWarning(dl)}
                <div>{L.driver()}: <span>{driverName}</span></div>
                {tractor && <div>{L.tractor()}: <span>{tractor}</span></div>}
                {trailer && <div>{L.trailer()}: <span>{trailer}</span></div>}
                {load && <div>{L.load()}: <span>{load.id}</span></div>}
                <Timestamp>{localDateTimeDisplay(dl.timestamp)}</Timestamp>
            </div>
        );
    };

    const renderSkeleton = () => {
        return (
            <>
                <Skeleton width={100}/>
                <Skeleton width={100}/>
                <Skeleton width={100}/>
            </>
        );
    };

    return (
        <ListItem className='live-map-sidebar-item'>
            <DeviceCard ref={scrollRef}>
                <CardContent
                    data-testid={`live-map-sidebar-item-${dl.deviceId}`}
                    onClick={() => {
                        onClick(dl);
                        executeScroll();
                        // scrollToElement(scrollRef);
                    }}>
                    <div>
                        {isLoading ? renderSkeleton() : renderContent()}
                    </div>
                    <ArrowForwardIosIcon sx={{ color: '#aaa' }} />
                </CardContent>
            </DeviceCard>
        </ListItem>
    );
};

export const LiveMapList: React.FC<{ deviceLocations?: DeviceLocation[], onClick: (deviceLocation: DeviceLocation) => void, selectedDevice?: DeviceLocation | null }> = (props) => {
    const { deviceLocations = [], onClick, selectedDevice } = props;
    const dupedFields = findDuplicateResourceIdsForFields(deviceLocations, ['orderId', 'userId', 'tractorId', 'trailerId'])
    const parsedDeviceLocations = markDuplicateFieldsForLocations(deviceLocations, dupedFields)

    return (
        <>
            {parsedDeviceLocations ? <Typography
                    variant='subtitle2'>{L.liveMapTrackedItemsCount(`${parsedDeviceLocations?.length}`)}</Typography> :
                <Skeleton/>}
            <List>
                {parsedDeviceLocations?.map(dl => <DeviceListItem key={dl.deviceId} deviceLocation={dl}
                                                                  onClick={onClick}
                                                                  isSelected={dl.deviceId === selectedDevice?.deviceId}/>)}
            </List>
        </>
    );
};

