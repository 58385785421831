import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';
import React, { FormEvent } from 'react';
import { DynamicQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { ChecklistTemplate, CreateTractor, KeysWithValsOfType, Tractor } from '../../../../types';
import { isValidVinNumber } from '../../../../utils/validation-utils';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { CheckboxContract } from '../../../shared/inputs/checkbox-contract';
import { CheckboxEnabled } from '../../../shared/inputs/checkbox-enabled';
import { Select } from '../../../shared/inputs/select';

interface EditCreateTractorProps {
    existingTractor: Tractor | null;
    organizationId: number;
    onSubmit: (t: Tractor | CreateTractor) => void;
}

export const EditCreateTractor: React.FC<EditCreateTractorProps> = (props) => {
    const { existingTractor, organizationId, onSubmit } = props;
    const [tractor, setTractor] = React.useState<Tractor | CreateTractor>(existingTractor || {
        userDisplayName: '',
        organizationId: organizationId,
        vinNumber: null,
        licensePlate: null,
        make: null,
        model: null,
        contract: false,
        enabled: true,
        checklistTemplateId: null,
    });
    const { data: checklistTemplates } = useOrganizationQuery<ChecklistTemplate[]>(DynamicQueryKeys.resourceTypeChecklistTemplates('tractor'));
    const vinError = React.useMemo(() => !isValidVinNumber(tractor.vinNumber), [tractor.vinNumber]);

    const checklistTemplateSelectItems = checklistTemplates?.map(template => ({...template, label: template.name })) || [];
    const selectedChecklistTemplate = checklistTemplateSelectItems?.find(template => template.id === tractor.checklistTemplateId);

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
 
        onSubmit(tractor);
    };
    const renderTextField = (label: string, keyValue: KeysWithValsOfType<Tractor, string | null>, required: boolean, error?: boolean) => {
        return (
            <TextField
                variant='standard'
                label={label}
                required={required}
                value={tractor[keyValue] || ''}
                error={error}
                helperText={error && L.invalidVin()}
                onChange={(e) => {
                    setTractor({ ...tractor, [keyValue]: e.target.value });
                }}
                style={{
                    padding: '.25rem 0',
                    width: '100%'
                }} />
        );
    };

    return (
        <ResourceEditCreateContainer width='20vw'>
            <form id='edit-create-tractor-form' name='edit-create-tractor-form' onSubmit={submitForm}>
                {renderTextField(L.name(), 'userDisplayName', true)}
                {renderTextField(L.vinNumber(), 'vinNumber', false, vinError)}
                {renderTextField(L.licensePlate(), 'licensePlate', false)}
                {renderTextField(L.make(), 'make', false)}
                {renderTextField(L.model(), 'model', false)}
                <Select
                    label={L.checklistTemplate()}
                    list={checklistTemplateSelectItems}
                    item={selectedChecklistTemplate}
                    onChange={(checklistTemplate) => setTractor({...tractor, checklistTemplateId: checklistTemplate.id})} />
                <CheckboxContract
                    checked={tractor.contract}
                    onChange={() => setTractor({ ...tractor, contract: !tractor.contract })} />
                <CheckboxEnabled
                    checked={tractor.enabled}
                    onChange={() => setTractor({ ...tractor, enabled: !tractor.enabled })} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button type='submit'
                        title={L.save()}
                        disabled={vinError}
                        variant='contained'
                        color='primary'>{existingTractor ? L.updateTractor() : L.createTractor()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};
