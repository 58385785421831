import React from 'react';
import { useLoadContext } from '../load-context';
import {
    getPairedStop,
    orgLocationsToItems,
    stopCargoEligibleForCompartments,
    stopToLoadingTeamIdLabelTuple,
} from '../../../utils/data-mapping-utils';
import { getTransCargoTypeLabel, isCargoStop, STOP_STATUSES, STOP_TYPES, WASH_OPTIONS } from '../../../constants/constants';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { ConditionalEditStopProperties, useStopContext } from '../stop-context';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { CompartmentDiagram } from '../compartment-diagram';
import { ResourceConflictContainer } from '../resource-conflicts/resource-conflict-container';
import { loadStopsMapper } from '../../loads/table-cells/load-stops';
import { useLocationTypes } from '../../../api/queries/use-location-types';
import { LoadingTeam, OrganizationLocation, Stop, StopType as StopTypeType, WashOption } from '../../../types';
import { Item, ResourceSelect } from '../inputs/resource-select';
import { AutoItem, ItemId, ResourceAutoComplete } from '../inputs/resource-autocomplete';
import TextField from '@mui/material/TextField';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { Box, IconButton, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import { ValidatedTextField, Validation } from '../inputs/validated-text-field';
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined';
import { StopNotesModal } from './stop-notes-modal';
import { StopImagesModal } from './stop-images-modal';
import { L } from 'harmony-language';
import { isValidStopLocation } from '../../../utils/validation-utils';
import { loadToOrganizationLocationIdPairs, orgSubLocationToItems, toSiteDisplayName } from '../../../utils/data-mapping';
import { useWeights } from '../hooks/use-weights';
import { TravelTimesCache, findUnknownPairsInCache, updatePointlessPairs } from '../../travel-times/use-travel-times';
import { useQueryClient } from '@tanstack/react-query';
import { useAddTravelTimes } from '../../../api/mutations/add/use-add-travel-times';
import { UnitsWeight } from 'harmony-constants';
import { useUser } from '../../../api/queries/use-user';
import { Functions } from '@mui/icons-material';
import { AgisticsDateTimePicker } from '../inputs/agistics-date-time-picker';
import { AgisticsMultiSelect } from '../multi-select/agistics-multi-select';

const inputProps = { style: { fontSize: '.875rem' } };

const stopHasCargo = (stop: Stop) => {
    return stop.type === 'Origin' || stop.type === 'Destination';
}

type StopTableCellProps = {
    required?: boolean;
    validations?: Validation[];
}

export const StopType: React.FC<StopTableCellProps> = (props) => {
    const { required } = props
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();

    const stopTypes = React.useMemo(() => Object.values(STOP_TYPES()).filter(t => t.key !== 'Wash').map(type => ({
        id: type.key,
        label: type.label,
    })), []);

    if (onChange) {
        return (
            <TextField
                variant='standard'
                fullWidth
                disabled={isSaving || stopEditDisabled}
                InputProps={inputProps}
                select
                required={required}
                value={stop.type}
                onChange={(e) => {
                    const type = e.target.value as StopTypeType;
                    onChange({ type })
                }}>
                {stopTypes.map(t => {
                    return (
                        <MenuItem key={t.id} value={t.id}>
                            {t.label}
                        </MenuItem>
                    )
                })}
            </TextField>
        );
    }

    return (
        <Tooltip
            title={<span style={{ fontSize: '.875rem' }}>{STOP_STATUSES()[stop.status].label}</span>}
            arrow
            placement='right'>
                <span style={{ color: STOP_STATUSES()[stop.status].color, fontWeight: 'bold' }}>{STOP_TYPES()[stop.type].label}</span>
        </Tooltip>
    );
};

export const StopLocation: React.FC<void> = () => {
    const { stop } = useStopContext();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: locationTypes } = useLocationTypes();
    const locId = stop.organizationLocationId;
    // this needs to be re-worked. loadStopsMapper I think used to be some old component stuff with assignments?
    const stopInfo = loadStopsMapper(organizationLocations, locationTypes)(stop);
    const stopLocationName = React.useMemo(() => {
        const organizationLocation = organizationLocations?.find(x => x.id === locId);
        const sublocationNames = organizationLocation?.orgSubLocations?.filter(x => stop.organizationSubLocationIds?.includes(x.id)).map(x => x.name).join(', ');
        return organizationLocation ? toSiteDisplayName(organizationLocation.name, organizationLocation.description, sublocationNames) : '';
    }, [stop]);

    return (
        <ResourceConflictContainer key={locId} resource={{
            id: locId,
            type: 'location',
            cargoTypeIds: stopInfo.cargoTypes,
            stop
        }}>
            <span title={stopLocationName}>{stopLocationName}</span>
        </ResourceConflictContainer>
    )
}

export const StopLocationAuto: React.FC<void> = () => {
    const { load } = useLoadContext();
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();
    const { data: locations = [] as OrganizationLocation[] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: locationTypes = [] } = useLocationTypes();
    const locationList: AutoItem[] = React.useMemo(() => orgLocationsToItems(locations, locationTypes, stop),
        [locations, locationTypes, stop]);
    const isStopLocationValid = React.useCallback(() => isValidStopLocation(stop.organizationLocationId || -1),
        [stop]);
    const { organizationId } = useUser();
    const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient()
    const travelTimesCache = queryClient.getQueryData<TravelTimesCache>(([queryKey]));
    const { mutate: addTravelTimes } = useAddTravelTimes(organizationId);

    React.useEffect(() => {
        if (travelTimesCache) {
            const p = loadToOrganizationLocationIdPairs(load);
            const unknowPairs = findUnknownPairsInCache(travelTimesCache, p);
            const filteredUnknowPairs = updatePointlessPairs(unknowPairs, travelTimesCache, queryClient, queryKey);

            if (filteredUnknowPairs.length) {
                addTravelTimes(unknowPairs);
            }
        }
    }, [stop.organizationLocationId]);

    if (onChange) {
        return (
            <ResourceAutoComplete
                initialSelectedOptionId={stop.organizationLocationId || -1}
                options={locationList}
                onChange={(optionId: ItemId) => {
                    if (optionId) {
                        onChange({
                            organizationLocationId: optionId,
                            organizationSubLocationIds: null,
                            orgLocation: locations.find(l => l.id === stop.organizationLocationId),
                        });
                    }
                }}
                isLoading={isSaving}
                fontSize={true}
                validations={[
                    {
                        isValid: isStopLocationValid,
                        message: L.invalidLocation(),
                    }
                ]}
                disabled={stopEditDisabled}
            />
        )
    }

    return null;
}

export const StopSubLocations: React.FC = () => {
    const { stop, onChange, stopEditDisabled } = useStopContext();
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const subLocationList = orgSubLocationToItems(locations, stop.organizationLocationId);

    if (onChange && subLocationList.length) {
        return (
            <AgisticsMultiSelect
                selectedIds={stop.organizationSubLocationIds || []}
                setSelectedIds={(organizationSubLocationIds) => {
                    onChange({
                        organizationSubLocationIds: organizationSubLocationIds,
                    })
                }}
                items={subLocationList}
                confinedSpace={true}
                disabled={stopEditDisabled}
            />
        );
    }

    return null;
}

export const StopTime: React.FC<StopTableCellProps> = (props) => {
    const { required } = props;
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();
    const { load } = useLoadContext()

    const validations = React.useMemo(() => {
        return props.validations?.map(v => ({ ...v, isValid: () => v.isValid(stop) }));
    }, [stop, props.validations])

    if (onChange) {
        return (
            <AgisticsDateTimePicker
                value={stop.arrivalTime}
                referenceDate={stop.arrivalTime || load.date}
                required={required || false}
                disabled={isSaving || stopEditDisabled}
                validations={validations}
                smallText={true}
                onChange={(time) => onChange({ arrivalTime: time })}
            />
        )
    }

    if (stop.type === 'Wash') {
        return <span>{WASH_OPTIONS()[stop.arrivalTime as WashOption].label}</span>
    }
    return <span>{stop.arrivalTime && localDateTimeDisplay(stop.arrivalTime)}</span>
}

export const StopCompletedTime: React.FC = () => {
    const { stop } = useStopContext();
    const time = stop.completedAt;

    return <span>{time ? localDateTimeDisplay(time) : null}</span>
}

export const StopLoadingTeam: React.FC<StopTableCellProps> = (props) => {
    const { required } = props;
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();
    const { data } = useOrganizationQuery<LoadingTeam[]>(OrgQueryKeys.loadingTeams);
    const loadingTeams = data || [];
    const loadingTeamList: Item[] = React.useMemo(() => {
        return loadingTeams.map((x: LoadingTeam) => ({
            id: x.id,
            label: x.name
        })).sortBy((x: Item) => x.label)
    }, [loadingTeams]);

    if (!isCargoStop(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <ResourceSelect itemId={stop.loadingTeamId} itemList={loadingTeamList} resourceType={'loadingTeam'}
                            isDisabled={isSaving || stopEditDisabled}
                            required={required}
                            inputProps={inputProps}
                            stop={stop}
                            onChange={(id) => onChange({ loadingTeamId: id })}/>
        )
    }

    return (
        <ResourceConflictContainer resource={{ id: stop.loadingTeamId, type: 'loadingTeam', stop: stop }}>
            <span>{stopToLoadingTeamIdLabelTuple(loadingTeams)(stop).label}</span>
        </ResourceConflictContainer>
    )
}

export const StopQuantity: React.FC<StopTableCellProps> = (props) => {
    const { validations } = props;
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <ValidatedTextField
                validations={validations}
                type='number'
                // required={required}
                disabled={isSaving || stopEditDisabled}
                value={stop.quantity || ''}
                inputProps={{ min: 1, ...inputProps }}
                fullWidth
                onChange={(e) => onChange({ quantity: Number(e.target.value) })}/>
        )
    }

    return <span>{stop.quantity}</span>
}

export const StopWeight: React.FC<StopTableCellProps> = (props) => {
    const { validations } = props;
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();
    const { weightAbbreviation, convertFromGrams, convertToGrams, convertFromGramsDisplay } = useWeights();
    const [displayWeight, setDisplayWeight] = React.useState(convertFromGrams(stop.weight || 0));

    // This will re-calculate displayWeight when user weight unit preference is changed
    React.useEffect(() => {
        setDisplayWeight(convertFromGrams(stop.weight || 0));
    }, [convertFromGrams]);

    if (!stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <ValidatedTextField
                // validations={stopValidations}
                validations={validations}
                type='number'
                // required={required}
                disabled={isSaving || stopEditDisabled}
                value={displayWeight || ''}
                inputProps={inputProps}
                fullWidth
                onChange={(e) => {
                    const changedWeight = Number(e.target.value);
                    const grams = convertToGrams(changedWeight);
                    setDisplayWeight(changedWeight);
                    onChange({ weight: grams });
                }}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>
                }} />
        )
    }

    if (!stop.weight) {
        return null;
    }

    return <Box component={'span'}>{convertFromGramsDisplay(stop.weight)}</Box>;
}

export const StopActualQuantity: React.FC = () => {
    const { stop } = useStopContext();

    return <span>{stop.actualQuantity}</span>
}

export const StopActualWeight: React.FC = () => {
    const { stop } = useStopContext();
    const { convertFromGramsDisplay } = useWeights();

    if (!stop.actualWeight) {
        return null;
    }

    return <Box component={'span'}>{convertFromGramsDisplay(stop.actualWeight)}</Box>;
}

export const StopCargoType: React.FC<StopTableCellProps> = (props) => {
    const { required } = props;
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();
    const { cargoTypeList, isLoading } = useCargoTypes();
    const { load } = useLoadContext();

    if (isLoading || !stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <ResourceSelect hideNone itemId={stop.cargoTypeId} itemList={cargoTypeList}
                            isDisabled={isSaving || stopEditDisabled}
                            required={required}
                            inputProps={inputProps}
                            onChange={(id) => {
                                const allLoadCargoTypes = load.stops.map(x => x.cargoTypeId);
                                if (allLoadCargoTypes.every(x => x === null)) {
                                    load.stops.map(x => x.cargoTypeId = id);
                                }
                                onChange({ cargoTypeId: id })
                            }}/>
        )
    }

    return <span>{stop.cargoTypeId ? getTransCargoTypeLabel(stop.cargoTypeId) : null}</span>
}

type ExtendableStop = Stop & { [key: string]: boolean };

const ConditionalStopPropertyEdit = (propertyName: keyof ConditionalEditStopProperties, showOnCargoStopsOnly = true) => () => {
    const { stop, onChange, isSaving, canEditProperty, stopEditDisabled } = useStopContext();
    const [value, setValue] = React.useState(stop[propertyName]);

    if (showOnCargoStopsOnly && !isCargoStop(stop)) {
        return null;
    }

    if (!canEditProperty[propertyName] && !(stop as ExtendableStop)[propertyName + '_Editable']) {
        return <span>{stop[propertyName]}</span>
    } else {
        (stop as ExtendableStop)[propertyName + '_Editable'] = true;
        return (
            <TextField
                variant='standard'
                value={value || ''}
                disabled={isSaving || stopEditDisabled}
                fullWidth
                onChange={(e) => {
                    setValue(e.target.value)
                    if (onChange) {
                        onChange({ [propertyName]: e.target.value })
                    }
                }} />
        );
    }
}

export const EditableStopOrderNumber: React.FC = ConditionalStopPropertyEdit('orderNumber');
export const EditableStopLotId: React.FC = ConditionalStopPropertyEdit('lotId');

export const StopOrderNumber: React.FC = () => {
    const { stop } = useStopContext();
    return <span>{stop.orderNumber}</span>
}

export const StopProductionPlan: React.FC = () => {
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <TextField
                variant='standard'
                value={stop.productionPlan || ''}
                disabled={isSaving || stopEditDisabled}
                fullWidth
                onChange={(e) => {
                    if (onChange) {
                        onChange({ productionPlan: e.target.value })
                    }
                }} />
        );
    }

    return <span>{stop.productionPlan}</span>;
}

export const StopLotId: React.FC = () => {
    const { stop } = useStopContext();
    return <span>{stop.lotId}</span>
}

export const StopCompartments: React.FC = () => {
    const { stop, onChange, stopEditDisabled } = useStopContext();
    const { load, editFunctions } = useLoadContext();
    const { data: cargoTypes, isLoading: isLoadingCargoTypes } = useCargoTypes();

    if (isLoadingCargoTypes || !stopCargoEligibleForCompartments(cargoTypes, stop) || stopEditDisabled) {
        return null;
    }

    const compartmentChanged = (compartmentNumber: number) => {
        if (!onChange) {
            return
        }

        let pairedStop: Stop;
        if (editFunctions?.compartmentLinkingEnabled) {
            pairedStop = getPairedStop(load, stop);
        }

        const enabledCompartments = stop?.compartments ? [...stop.compartments] : [];
        const matchIndex = enabledCompartments.indexOf(compartmentNumber);
        if (matchIndex > -1) {
            enabledCompartments.splice(matchIndex, 1);
        } else {
            enabledCompartments.push(compartmentNumber);
        }

        editFunctions?.onChange({
            ...load,
            stops: load.stops.map(x => {
                if (x.sequence === stop.sequence || x.sequence === pairedStop?.sequence) {
                    return { ...x, compartments: enabledCompartments }
                } else {
                    return x;
                }
            })
        });
    };

    return <CompartmentDiagram onChange={onChange ? compartmentChanged : undefined} trailerId={load.trailerId}
                               cargoTypeId={stop.cargoTypeId}
                               compartments={stop.compartments || []} hideLabel={true}/>
}

export const StopCargoPerCompartment: React.FC = () => {
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <TextField
                variant='standard'
                type='number'
                disabled={isSaving || stopEditDisabled}
                value={stop.cargoPerCompartment || ''}
                inputProps={{ min: 1, ...inputProps }}
                fullWidth
                onChange={(e) => onChange({ cargoPerCompartment: Number(e.target.value) })} />
        );
    }

    return <span>{stop.cargoPerCompartment}</span>
}

export const StopSplitIcon: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    const { stop, stopEditDisabled } = useStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    return (
        <IconButton
            size='small'
            color='primary'
            disabled={!stop.id || !load.id || stopEditDisabled}
            onClick={() => editFunctions?.splitStop(stop)}
        >
            <CallSplitOutlinedIcon/>
        </IconButton>
    )
}

export const StopNotes: React.FC = () => {
    const { stop, onChange, stopEditDisabled } = useStopContext();

    return (
        <div>
            <StopNotesModal stop={stop} onChange={onChange} stopEditDisabled={stopEditDisabled} />
            <StopImagesModal stop={stop} />
        </div>
    )
}

export const StopCustomerOrderNumber: React.FC = () => {
    const { stop, onChange, isSaving, stopEditDisabled } = useStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    if (onChange) {
        return (
            <TextField
                variant='standard'
                value={stop.customerOrderNumber || ''}
                disabled={isSaving || stopEditDisabled}
                fullWidth
                onChange={(e) => {
                    onChange({ customerOrderNumber: e.target.value })
                }} />
        );
    }

    return <span>{stop.customerOrderNumber}</span>;
}

interface StopRequiredTimestampProps {
    colName: 'requiredBegin' | 'requiredEnd';
}

export const StopRequiredTimestamp: React.FC<StopRequiredTimestampProps> = (props) => {
    const { colName } = props;
    const { stop, onChange } = useStopContext();
    const dateTime = stop[colName];

    if (onChange) {
        return (
            <AgisticsDateTimePicker
                value={dateTime}
                onChange={(dt: string | null) => onChange({ [colName]: dt || null })}
                smallText={true}
            />
        )
    }

    return <span>{dateTime ? localDateTimeDisplay(dateTime) : null}</span>
};

export const StopReadyTime: React.FC = () => {
    const { stop, onChange } = useStopContext();
    const dateTime = stop.readyTime;

    if (onChange) {
        return (
            <AgisticsDateTimePicker
                value={dateTime}
                onChange={(dt: string | null) => onChange({ readyTime: dt || null })}
                smallText={true}
            />
        )
    }

    return <span>{dateTime ? localDateTimeDisplay(dateTime) : null}</span>
}

export const StopAutoCalc: React.FC = () => {
    const { stop, calculateStopTimes, isSaving, stopEditDisabled } = useStopContext();

    return (
        <Tooltip
            title={L.calculateOtherStopTimes()}
            placement='right-start'
        >
            <span>
                <IconButton
                    size='small'
                    onClick={() => calculateStopTimes(stop.sequence)}
                    disabled={!stop.arrivalTime || isSaving || stopEditDisabled}
                    sx={{ padding: 0 }}
                >
                    <Functions fontSize='inherit' />
                </IconButton>
            </span>
        </Tooltip>
    )
}
